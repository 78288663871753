<template>
  <v-col cols="8">
    <v-hover v-slot="{ hover }">
      <label for="inputChangeAvatar">
        <v-card :elevation="0">
          <v-avatar
            color="grey lighten-2"
            width="100%"
            height="100%"
            v-if="infoUser.user_info.avatar"
          >
            <v-img aspect-ratio="1" :src="infoUser.user_info.avatar"></v-img>
          </v-avatar>
          <v-avatar v-else color="grey lighten-2" size="200">
            <v-icon dark size="100">mdi-account-outline</v-icon>
          </v-avatar>
          <v-fade-transition>
            <v-overlay v-if="hover" absolute>
              <v-icon dark size="50">mdi-camera</v-icon>
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </label>
    </v-hover>
    <v-file-input
      id="inputChangeAvatar"
      accept="image/png, image/jpeg, image/bmp"
      placeholder="Pick an avatar"
      prepend-icon="mdi-camera"
      label="Avatar"
      hide-input
      style="display: none"
      @change="getImage"
    ></v-file-input>
    <v-fade-transition>
      <v-overlay v-if="editImage" absolute>
        <crop-image
          :src="avatarEdit"
          :width="avatarEditSize.canvas.width"
          :height="avatarEditSize.canvas.height"
          @uploadAvatar="uploadAvatar"
          @closeEdit="editImage = false"
        />
      </v-overlay>
    </v-fade-transition>
  </v-col>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { updateAvatar } from '@/helper/user'
import CropImage from "./../other/cropImage";

export default {
  name: "ProfileAvatar",
  components: {
    CropImage,
  },
  computed: {
    ...mapGetters(["infoUser"]),
  },
  mounted() {
    // Register an event listener when the Vue component is ready
    window.addEventListener("resize", this.onResize);
  },

  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener("resize", this.onResize);
  },

  data() {
    return {
      editImage: false,
      avatarEdit: "",
      avatarEditDataRaw: "",
      avatarEditSize: {
        origin: {
          width: 0,
          height: 0,
          tl: 0,
          data: "",
        },
        canvas: {
          width: 0,
          height: 0,
          url: "",
        },
      },
    };
  },
  methods: {
    ...mapActions(['reloadUserInfo']),
    uploadAvatar(avatarBase64) {
      let file = this.dataURLtoFile(avatarBase64, "avatar.png");
      this.infoUser.user_info.avatar = URL.createObjectURL(file);

      updateAvatar(file, {
        done: data => {
          this.reloadUserInfo()
          this.loading = false
        },
        error: res => {
          alert('Upload ảnh thất bại')
          console.log('Upload ảnh thất bại', res)
          this.loading = false
        }
      })

      // this.infoUser.user_info.avatar = b64toBlob(avatarBase64);
      this.editImage = false;
    },
    getImage(e) {
      var i = new Image();
      i.onload = () => {
        this.avatarEditSize.origin.data = this.getDataImgTag(i);
        this.avatarEditSize.origin.width = i.width;
        this.avatarEditSize.origin.height = i.height;
        this.avatarEditSize.origin.tl = i.width / i.height;
        this.updateSizeAvatar();
        // i.width = this.avatarEditSize.canvas.width;
        // i.height = this.avatarEditSize.canvas.height;
        // this.avatarEdit = URL.createObjectURL(
        //   this.dataURLtoFile(this.getDataImgTag(i), "avatar.png")
        // );
        // console.log(this.avatarEdit);
        this.editImage = true;
      };
      this.avatarEdit = i.src = URL.createObjectURL(e);
    },
    updateSizeAvatar() {
      const tl = this.avatarEditSize.origin.tl;
      const sw80 = Math.floor(window.innerWidth * 0.8);
      const sh80 = Math.floor(sw80 / (window.innerWidth / window.innerHeight));

      var awidth = this.avatarEditSize.origin.width;
      var aheight = this.avatarEditSize.origin.height;

      if (awidth > sw80) {
        // cập nhật theo chiều rộng
        awidth = sw80;
        aheight = Math.round(awidth / tl);
      }

      if (aheight > sh80) {
        // cập nhật theo chiều cao
        aheight = sh80;
        awidth = Math.round(aheight * tl);
      }

      this.avatarEditSize.canvas.width = awidth;
      this.avatarEditSize.canvas.height = aheight;
    },
    processAvatarEdit() {},
    onResize() {
      if (this.editImage) {
        this.updateSizeAvatar();
        this.editImage = false;

        setTimeout(() => {
          this.editImage = true;
        }, 200);
      }
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    getDataImgTag(img) {
      // Create canvas
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      // Set width and height
      canvas.width = img.width;
      canvas.height = img.height;
      // Draw the image
      ctx.drawImage(img, 0, 0);
      return canvas.toDataURL("image/png");
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1);
        n -= 1; // to make eslint happy
      }
      return new File([u8arr], filename, { type: mime });
    },
  },
};
</script>
<template>
  <v-container id="user-profile-view" fluid tag="section" v-if="infoUser">
    <v-row justify="center">
      <v-col cols="12" sm="6" md="4" class="text-center">
        <v-row justify="center">
          <avatar />
          <v-col cols="12">
            <div>
              <div v-if="editNameShow">
                <!-- code -->
                <v-text-field
                  label="Tên hiển thị"
                  v-model="nameShow"
                  @keyup.enter="updateNameShow()"
                ></v-text-field>
                <v-btn @click="editNameShow = false" small class="ma-1"
                  >Hủy</v-btn
                >
                <v-btn
                  @click="updateNameShow()"
                  color="green"
                  small
                  class="ma-1"
                  dark
                  
                  :loading="loadingNameShow"
                  :disabled="loadingNameShow"
                >Sửa</v-btn>
              </div>
              <v-btn v-else @click="enabledEditNameShow()" small icon>
                <span class="text-h5" style="text-transform: capitalize">{{
                  infoUser.user_info.name_show
                }}</span>
                <v-icon right>mdi-pencil</v-icon>
              </v-btn>
            </div>
            <v-dialog v-model="dialogChangUsername" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn small color="success" class="ma-1" v-bind="attrs" v-on="on">
                  <v-icon left>mdi-pencil</v-icon>
                  Thay đổi tài khoản
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5">Thay đổi tài khoản</v-card-title>
                <v-card-text>Tính năng đang phát triển</v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialogChangUsername = false">Hủy</v-btn>
                  <v-btn color="primary" text @click="dialogChangUsername = false">Thay đổi</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogChangPassword" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn small color="warning" class="ma-1" v-bind="attrs" v-on="on">
                  <v-icon left>mdi-pencil</v-icon>
                  Thay đổi mật khẩu
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5">Thay đổi mật khẩu</v-card-title>
                <v-card-text>Tính năng đang phát triển</v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialogChangPassword = false">Hủy</v-btn>
                  <v-btn color="primary" text @click="dialogChangPassword = false">Thay đổi</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <br />
            <v-alert v-if="statusUser(infoUser.user_info)" dense outlined type="warning">{{
              statusUser(infoUser.user_info)
            }}</v-alert>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" md="8">
        <v-tabs class="hidden-md-and-down" v-model="tab" left>
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab v-for="(tabName, keyTab) in listTab" :key="keyTab">{{ tabName }}</v-tab>
        </v-tabs>
        <v-select
          :items="returnSelectTab()"
          v-model="tab"
          item-text="name"
          item-value="key"
          filled
          class="hidden-lg-and-up"
        ></v-select>

        <v-card flat class="mt-8">
          <v-card-text v-if="tab == 0">
            <!-- Tab khoi phuc -->
            <v-row justify="center">
              <v-expansion-panels v-model="panels" multiple>
                <v-expansion-panel>
                  <v-expansion-panel-header>Email</v-expansion-panel-header>
                  <v-expansion-panel-content v-if="getSecurityInfo.email.length > 0">
                    <v-list two-line>
                      <info-email v-for="(email, key) in getSecurityInfo.email" :key="key" :info="email" @reload="reloadUserInfo" /> 
                    </v-list>
                  </v-expansion-panel-content>
                  <v-expansion-panel-content v-else>Không có email</v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Số điện thoại</v-expansion-panel-header>
                  <v-expansion-panel-content v-if="getSecurityInfo.phone.length > 0">
                    <v-list two-line>
                      <info-phone v-for="(phone, key) in getSecurityInfo.phone" :key="key" :info="phone" /> 
                    </v-list>
                  </v-expansion-panel-content>
                  <v-expansion-panel-content v-else>Không có số điện thoại</v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-card-text>
          <v-card-text v-else>Tính năng sắp ra mắt</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { updateNameShow } from '@/helper/user'

import avatar from "@/components/profile/avatar";
import infoPhone from "@/components/profile/infoPhone"
import infoEmail from "@/components/profile/infoEmail"

export default {
  name: "UserInfo",
  components: {
    avatar,
    infoPhone,
    infoEmail
  },
  computed: {
    ...mapGetters(['getSecurityInfo', "infoUser", 'listUser']),
  },
  data() {
    return {
      tab: 0,
      editNameShow: false,
      nameShow: '',
      loadingNameShow: false,
      dialogChangUsername: false,
      dialogChangPassword: false,
      dialogReSendActiveEmail: false,
      dialogActiveEmail: false,

      tab: 0,
      panels: [0, 1],
      listTab: [
        'Thông tin khôi phục',
        'Thông tin cá nhân',
        'Thông tin thanh toán',
      ],
    };
  },
  methods: {
    ...mapActions(['reloadUserInfo']),

    enabledEditNameShow() {
      this.nameShow = this.infoUser.user_info.name_show
      this.editNameShow = true;
    },
    returnSelectTab() {
      var i = 0
      return this.listTab.map(elm => {
        i++;
        return {
          key: i-1,
          name: elm
        }
      })
    },
    updateNameShow() {
      if (this.nameShow !== this.infoUser.user_info.name_show) {
        updateNameShow({
          data: {
            name_show: this.nameShow
          },
          done: data => {
            this.reloadUserInfo(() => {
              this.editNameShow = false
              this.loadingNameShow = false
            })
          },
          error: res => {
            alert('Thay đổi không thành công')
            console.log(res)
            this.loadingNameShow = false
          }
        })
        this.infoUser.user_info.name_show = this.nameShow
      } else {
        this.editNameShow = false
      }
    },
    statusUser(userInfo) {
      if (Number(userInfo.user_status) !== 4) {
        switch (Number(userInfo.user_status)) {
          case 1: {
            return "Tài khoản đang trong thời gian xóa";
          }
          case 2: {
            return "Tài khoản đã bị khóa";
          }
          case 2: {
            return "Tài khoản đang vô hiệu hóa";
          }
          default: {
            return "Vui lòng đăng nhập hoặc đăng nhập lại để lấy dữ liệu";
          }
        }
      } else if (Number(userInfo.user_security) === 0) {
        return "Tài khoản chưa có phương thức bảo mật nào";
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <v-row>
      <div>
        <img :src="src" :width="width" :height="height" ref="image" />
      </div>
    </v-row>
    <v-row>
      <v-btn small color="error" class="ma-1" @click="$emit('closeEdit')"
        >Hủy</v-btn
      >
      <v-btn
        small
        color="green"
        class="ma-1"
        @click="$emit('uploadAvatar', destination)"
        >Upload</v-btn
      >
    </v-row>
  </div>
</template>
<script>
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";

export default {
  name: "CropImage",
  props: {
    src: String,
    width: Number,
    height: Number,
  },
  data() {
    return {
      cropper: {},
      destination: {},
      image: {},
    };
  },
  mounted() {
    this.image = this.$refs.image;
    this.cropper = new Cropper(this.image, {
      aspectRatio: 1,
      // zoomable: false,
      // scalable: false,
      crop: () => {
        const cavas = this.cropper.getCroppedCanvas();
        this.destination = cavas.toDataURL("image/png");
      },
    });
  },
};
</script>
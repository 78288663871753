<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>{{ info.email }}</v-list-item-title>
      <v-list-item-subtitle v-if="info.status !== 2">Chưa kích hoạt</v-list-item-subtitle>
      <v-list-item-subtitle v-else-if="info.type === 1">Email chính</v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action v-if="info.status !== 2">
      <v-dialog v-model="dialogActive" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn x-small class="ma-1" v-bind="attrs" v-on="on" @click="ActioOpenDialogActive()">Kích hoạt</v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5">Kích hoạt email</v-card-title>
          <v-card-text>
            <v-card-text v-if="msgErrorActive">{{ msgErrorActive }}</v-card-text>
            <v-otp-input length="6" v-model="otpActive" :disabled="loadingActive" @finish="methodActive" />
            <v-progress-linear v-if="loadingActive" indeterminate color="primary" class="mb-0" />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" text @click="dialogActive = false">Hủy</v-btn>
            <v-btn color="primary" :disabled="otpActive.length < 6 || loadingActive" text @click="methodActive()">Kích hoạt</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogReSendActive" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn x-small class="ma-1" v-bind="attrs" v-on="on" :disabled="loadingReSendActive" @click="methodReSendActive()">Gửi lại mã</v-btn>
        </template>

        <v-card v-if="loadingReSendActive">
          <v-card-title class="text-h5">Đang gửi mã kích hoạt về email</v-card-title>
          <v-card-text>Vui lòng chờ</v-card-text>
          <v-progress-linear indeterminate color="primary" class="mb-0" />
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" text @click="dialogReSendActive = false">Đóng</v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else>
          <v-card-title class="text-h5">Gửi lại mã kích hoạt</v-card-title>
          <v-card-text v-if="statusReSendActive">Đã gửi email kích hoạt vui lòng kiểm tra email kể cả trong phần spam và thư rác</v-card-text>
          <v-card-text v-else>Không thể yêu cầu gửi mã xác thực về email lúc này</v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" text @click="dialogReSendActive = false">Đóng</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list-item-action>
    <v-list-item-action v-else>
      <v-dialog v-model="dialogDelete" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn x-small class="ma-1" v-bind="attrs" v-on="on">Xóa</v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5">Xóa email</v-card-title>
          <v-card-text>Tính năng đang phát triển</v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" text @click="dialogDelete = false">Đóng</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { reSendEmailVerify, verifyEmail } from '@/helper/user'

export default {
  name: "InfoEmail",
  computed: {},
  props: {
    info: Object
  },

  data() {
    return {
      dialogActive: false,
      loadingActive: false,
      otpActive: '',
      msgErrorActive: '',

      dialogReSendActive: false,
      loadingReSendActive: false,
      statusReSendActive: false,

      dialogDelete: false,
    }
  },
  methods: {
    ActioOpenDialogActive() {
      this.msgErrorActive = ''
    },
    methodActive(otp=false) {
      if (otp) {
        this.otpActive = otp
      }
      this.loadingActive = true
      this.msgErrorActive = ''

      verifyEmail({
        data: {
          id_email: this.info.id,
          otp: this.otpActive,
        },
        done: data => {
          this.$emit('reload', () => {
            this.dialogActive = false
            this.loadingActive = false  
          })
        },
        error: res => {
          if (res.data.msg) {
            this.msgErrorActive = data.msg
          } else {
            this.msgErrorActive = 'Đã có lỗi xảy ra không thể kích hoạt lúc này'
          }
          console.log('Đã có lỗi xảy ra', res)
          this.loadingActive = false
        }
      })
    },

    methodReSendActive() {
      this.loadingReSendActive = true
      this.statusReSendActive = false

      // gửi lại email
      reSendEmailVerify({
        data: {
          id_email: this.info.id
        },
        done: data => {
          this.statusReSendActive = true
          this.loadingReSendActive = false
        },
        error: res => {
          console.log(res)
          this.loadingReSendActive = false
        },
      })
    }
  },
};
</script>